import React, { useEffect, useRef, useState } from "react";

const RevenueInfo = (props) => {
  const infoForm = useRef(null);
  const [revenueInfoData, setRevenueInfoData] = useState({});
  const [timeline, setTimeline] = useState("TODAY");

  const revenueInfoCardData = [
    {
      title: "Total Collections",
      count: revenueInfoData?.totalCollection?.repaymentCount || 0,
      amount: revenueInfoData?.totalCollection?.repaymentAmt || 0,
      icon: imagepaths.dollarCollectionIcon,
      path: `/app/portfolioinfo?cardname=totalCollection&timeline=${timeline}`,
    },
    {
      title: "Processing Fees",
      count: revenueInfoData?.revenueProcessingFees?.processingFeeCount || 0,
      amount: revenueInfoData?.revenueProcessingFees?.processingFeeAmt || 0,
      icon: imagepaths.dollarBagIcon,
      path: `/app/portfolioinfo?cardname=processingFees&timeline=${timeline}`,
    },
    {
      title: "Principle",
      count: revenueInfoData?.revenuePrinciple?.principleCount || 0,
      amount: revenueInfoData?.revenuePrinciple?.principleAmt || 0,
      icon: imagepaths.coinStackIcon,
      path: `/app/portfolioinfo?cardname=principle&timeline=${timeline}`,
    },
    {
      title: "Interest",
      count: revenueInfoData?.revenueInterest?.interestCount || 0,
      amount: revenueInfoData?.revenueInterest?.interestAmt || 0,
      icon: imagepaths.percentageRoundedIcon,
      path: `/app/portfolioinfo?cardname=interest&timeline=${timeline}`,
    },
  ];
  // REVENUE INFO
  const getRevenueTotalCollections = (query, params) => {
    let data = {
      ...query,
    };

    let payload = {};
    if (params) {
      payload = {
        timeline: params,
      };
    } else {
      payload = {
        timeline: timeline,
      };
    }
    props.apis.apiGetRevenueTotalCollections(data, payload).then((res) => {
      setRevenueInfoData((prevState) => ({
        ...prevState,
        totalCollection: res,
      }));
    });
  };
  const getRevenueProcessingFees = (query, params) => {
    let data = {
      ...query,
    };

    let payload = {};
    if (params) {
      payload = {
        timeline: params,
      };
    } else {
      payload = {
        timeline: timeline,
      };
    }
    props.apis.apiGetRevenueProcessingFees(data, payload).then((res) => {
      setRevenueInfoData((prevState) => ({
        ...prevState,
        revenueProcessingFees: res,
      }));
    });
  };
  const getRevenuePrinciple = (query, params) => {
    let data = {
      ...query,
    };

    let payload = {};
    if (params) {
      payload = {
        timeline: params,
      };
    } else {
      payload = {
        timeline: timeline,
      };
    }
    props.apis.apiGetRevenuePrinciple(data, payload).then((res) => {
      setRevenueInfoData((prevState) => ({
        ...prevState,
        revenuePrinciple: res,
      }));
    });
  };
  const getRevenueInterest = (query, params) => {
    let data = {
      ...query,
    };

    let payload = {};
    if (params) {
      payload = {
        timeline: params,
      };
    } else {
      payload = {
        timeline: timeline,
      };
    }
    props.apis.apiGetRevenueInterest(data, payload).then((res) => {
      setRevenueInfoData((prevState) => ({
        ...prevState,
        revenueInterest: res,
      }));
    });
  };

  const showInputs = (inputnames) => {
    if (inputnames.length > 0) {
      inputnames.forEach((id) => {
        const element = document.getElementById(id);
        if (element) {
          element.classList.remove("d-none");
        }
      });
    }
  };

  const hideInputs = (inputnames) => {
    if (inputnames.length > 0) {
      inputnames.forEach((id) => {
        const element = document.getElementById(id);
        if (element) {
          element.classList.add("d-none");
        }
      });
    }
  };

  const handleDateChange = (value) => {
    let val = value[0]?.key || "TODAY";
    let inputs = [
      "revenue-column-start_date",
      "revenue-column-end_date",
      "revenue-column-search_btn",
      "revenue-column-reset_btn",
    ];
    console.log({ val });
    if (val === "CUSTOM") {
      showInputs(inputs);
      setRevenueInfoData((prevState) => ({
        ...prevState,
        totalCollection: {},
        revenueProcessingFees: {},
        revenuePrinciple: {},
        revenueInterest: {},
      }));
    } else {
      setTimeline(val);
      hideInputs(inputs);
    }
  };

  const onSearch = () => {
    let data = infoForm.current.form.current.getData();
    let queryData = {
      ...Object.except(data, ["timeline"]),
    };
    getRevenueTotalCollections(queryData, "CUSTOM");
    getRevenueProcessingFees(queryData, "CUSTOM");
    getRevenuePrinciple(queryData, "CUSTOM");
    getRevenueInterest(queryData, "CUSTOM");
  };

  const resetSearchForm = () => {
    infoForm?.current?.form?.current?.reset();
    let inputs = [
      "revenue-column-start_date",
      "revenue-column-end_date",
      "revenue-column-search_btn",
      "revenue-column-reset_btn",
    ];
    hideInputs(inputs);
    getRevenueTotalCollections({}, "TODAY");
    getRevenueProcessingFees({}, "TODAY");
    getRevenuePrinciple({}, "TODAY");
    getRevenueInterest({}, "TODAY");
  };

  useEffect(() => {
    // OVERALL PORTFOLIO
    getRevenueTotalCollections();
    getRevenueProcessingFees();
    getRevenuePrinciple();
    getRevenueInterest();
  }, [timeline]);

  props.$setNode({
    props,
    revenueInfoData,
  });
  return (
    <>
      <div className="mb-4 dashboard_form_box">
        <div className="form_outer">
          <AppForm
            ref={infoForm}
            onSubmit={(...args) => onSearch(...args)}
            className="row justify-content-end"
          >
            <div
              className="col-12 col-md-3 col-lg-3 col-xl-2"
              id="column-timeline"
            >
              <AppInputFocus
                type="select"
                name="timeline"
                label="Time Line"
                options={_enum.OVERALL_DETAIL}
                onChange={(...args) => handleDateChange(...args)}
                defaultValue={"TODAY"}
                placeholder="Time Line"
              ></AppInputFocus>
            </div>
            <div
              className="col-12 col-md-3 col-lg-3 col-xl-2 d-none"
              id="revenue-column-start_date"
            >
              <AppInputFocus
                type="date"
                name="start_date"
                label="Start Date"
                placeholder="Start Date"
                required={true}
              ></AppInputFocus>
            </div>
            <div
              className="col-12 col-md-3 col-lg-3 col-xl-2 d-none"
              id="revenue-column-end_date"
            >
              <AppInputFocus
                type="date"
                name="end_date"
                label="End Date"
                placeholder="End Date"
                required={true}
              ></AppInputFocus>
            </div>
            <div
              className="col-2 col-md-1 col-lg-1 col-xl-1 d-none"
              id="revenue-column-search_btn"
            >
              <button type="submit" className="btn btn-primary search_btn">
                <i className="fa fa-search"></i>
                <span>Search</span>
              </button>
            </div>
            <div
              className="col-2 col-md-1 col-lg-1 col-xl-1 d-none"
              id="revenue-column-reset_btn"
            >
              <button
                type="button"
                className="btn btn-primary search_btn"
                onClick={(...args) => resetSearchForm(...args)}
              >
                <i class="fa-solid fa-filter-circle-xmark"></i>
                <span>Search</span>
              </button>
            </div>
          </AppForm>
        </div>
        <div className="row pt-0 pt-md-4">
          {revenueInfoCardData &&
            revenueInfoCardData.map((revenue) => {
              return (
                <>
                  <div
                    className="col-xl-3 col-sm-6"
                    onClick={(...args) => navigate(revenue?.path)}
                  >
                    <div className="card dashboard_card application_card revenue_info_card">
                      <div className="border_bottom_line"></div>
                      <div className="card-body">
                        <div className="icon_container">
                          <p className="icon_title">{revenue?.title}</p>
                          <div className="icon">
                            <img src={revenue?.icon} alt="icon image" />
                          </div>
                        </div>
                        <div className="content">
                          <p className="count">{revenue?.count}</p>
                          <p className="card_label">
                            Amount :{" "}
                            <span className="primary-red ms-1">
                              {revenue?.amount?.cur()}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default constore(RevenueInfo);
